import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px auto;
  max-width: 320px;
  width: 80%;
  font-family: 'Shadows Into Light';
  font-weight: 'bold';
  font-size: 1.2em;
`;
