import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: grid;
  grid-template-rows: 3fr 1fr 1fr;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const Picture = styled.div`
  background-image: url( ${ ({ background }) => background});
  background-size: cover;
  border-radius: 50%;
  border: 5px solid #222222;
  height: 200px;
  margin: 0 auto;
  width: 200px;
`;

export const Title = styled.h1`
  font-size: 3em;
  font-family: 'Shadows Into Light';
  font-weight: bold;
  padding: 10px 0px 10px 0px;
`

export const Subtitle = styled.p`
  font-size: 1.4em;
  font-family: 'Shadows Into Light';
  padding: 0px 0px 10px 0px;
  font-weight: bold;
`
