
import './App.css';
import './index.css'
import LinkTree from './components/LinkTree';

function App() {
  document.body.classList.add('jaidGradient');
  return (
    <LinkTree />
  );
}

export default App;
